.chatBody__content{
    position: relative; 
    height: 100%; 
    overflow: hidden; 
    &__wrap {
        overflow: scroll; 
        height: 100%;
        max-height: 100%;
        width: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        
        &::-webkit-scrollbar {
            display: none; 
        }
        scrollbar-width: none;
        &__myMsg{
            // background: #1E2122;
            background-color: rgba(0, 0, 0, 0.7);
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.6);
            backdrop-filter: blur(5px);
            padding: 12px;
            width: max-content;
            border-radius: 8px;
            margin-left: auto;
            width: auto;
            max-width: 80%;
            display: flex;
            flex-direction: column;
            gap: 6px;
            &__time{
                color: #848b8f;
                font-family: Inter;
                font-size: 13px;
                font-weight: 400;
                line-height: 19.36px;
                text-align: left;
            }
            &__content{
                font-family: Inter;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: left;
                color: #fff;
                &>img{
                    width: 100%;
                    max-width: 300px;
                    height: auto;
                }
            }
        }
        &__othersMsg{
            display: flex;
            gap: 15px;
            width: auto;
            max-width: 80%;
            &__left{
                &>img{
                    width: 44px;
                    height: 44px;
                    border-radius: 50%;
                }
            }
            &__right{
                // background: #2B2F30;
                background-color: rgba(0, 0, 0, 0.6);
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.6);
                backdrop-filter: blur(2px);
                padding: 12px;
                width: max-content;
                border-radius: 8px;
                display: flex;
                flex-direction: column;
                gap: 6px;
                &__time{
                    color: #848b8f;
                    font-family: Inter;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 19.36px;
                    text-align: left;
                    &>span{
                        font-size: 13px;
                        margin-left: 16px;
                    }
                }
                &__content{
                    font-family: Inter;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: left;
                    color: #fff;
                    &>img{
                        width: 100%;
                        max-width: 300px;
                        height: auto;
                    }
                }
            }
        }
        &__dateSeparator{
            font-family: Inter;
            font-size: 16px;
            font-weight: 400;
            line-height: 19.36px;
            text-align: left;
            color: #fff;
            text-align: center;
            margin-top: 20px;
            margin-bottom: 10px;
            position: relative;
            &__line{
                width: 100%;
                border-bottom: 1px solid #343839;
                position: absolute;
                top: 50%;
                z-index: 1;
            }
            &>span{
                position: relative;
                display: inline-block;
                padding: 4px 40px;
                // background-color: #232627;
                background-color: rgba(0, 0, 0, 0.2);
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
                backdrop-filter: blur(10px);
                border-radius: 10px;
                z-index: 2;
            }
        }
        &__message{
            font-family: Inter;
            font-size: 18px;
            font-weight: 400;
            line-height: 19.36px;
            text-align: left;
            color: #fff;
            margin: auto;
        }
    }
    
    &__scrollArrow {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%) translateY(10px);
        cursor: pointer;
        background: rgba(0, 0, 0, 0.3);
        color: #fff;
        padding: 10px;
        border-radius: 50%;
        transition: opacity 0.3s ease, transform 0.3s ease;

        &.hidden {
            opacity: 0;
            pointer-events: none;
            transform: translateX(-50%) translateY(10px);
        }
        
        &.visible {
            opacity: 1;
            transform: translateX(-50%) translateY(0);
        }

        &:hover {
            background: rgba(0, 0, 0, 0.5);
        }
    }
    
}