.chatBody__head{
    width: 100%;
    height: 68px;
    display: flex;
    align-items: center;
    padding: 20px;
    border-bottom: 2px solid #343839;
    &>img{
        width: 34px;
        height: 34px;
        border-radius: 50%;
        margin-right: 14px;
    }
    &__name{
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.36px;
        text-align: left;
        margin-right: 40px;
        color: #fff;
    }
    &__item{
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.36px;
        text-align: left;
        margin-right: 10px;
        color: #fff;

        padding: 7px 10px;
        cursor: pointer;
        border-bottom: 1px solid;
        border-color: transparent;
        transition: 0.3s ease;
    }
    &__right{
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.36px;
        text-align: left;
        margin-right: 10px;
        color: #fff;
        margin-left: auto;
        display: flex;
        &__item{
            cursor: pointer;
            padding: 2px 6px;
            width: max-content;
            transition: 0.3s ease;
            color: #E8ECEFBF;
            &:hover{
                color: #fff;
            }

            &__active{
                color: #fff;
            }
        }
        &>p{
            display: flex;
            align-items: center;
            &>img{
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }
    }
}



.channelMembersModal {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.8);

    &__wrapper {
        border-radius: 8px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 480px;
        background-color: #141718;
        position: relative;
        &__headline{
            font-family: Inter;
            font-size: 18px;
            font-weight: 400;
            line-height: 19.36px;
            text-align: left;
            margin-right: 10px;
            color: #fff;
            margin-bottom: 20px;
        }
        &__close{
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
        }
        &__members{
            display: flex;
            flex-direction: column;
            gap: 8px;
            max-height: 60vh;
            overflow: scroll;
            &__item{
                font-family: Inter;
                font-size: 16px;
                font-weight: 400;
                line-height: 19.36px;
                text-align: left;
                color: #fff;
                display: flex;
                align-items: center;
                gap: 8px;
                &>img {
                    width: 40px;
                    height: 40px;
                    object-fit: cover;
                    border-radius: 50%; /* Optional: to make it circular */
                }
            }
        }
    }
}