.component__createTaskModal{
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.7);
    &__wrapper{
        width: 100%;
        max-width: 640px;
        height: auto;
        border-radius: 8px;
        background-color: #141718;
        padding: 30px;
        position: relative;
        max-height: 100%;
        overflow: scroll;
        transition: 0.3s ease;
        &__close{
            position: absolute;
            top: 30px;
            right: 30px;
            cursor: pointer;
        }
        &__header{
            font-family: Inter;
            font-size: 18px;
            font-weight: 600;
            line-height: 21.78px;
            text-align: left;
            color: #fff;
            align-items: center;
            display: flex;
            gap: 20px;
        }
        &__label{
            display: flex;
            align-items: center;
            gap: 12px;
            font-family: Inter;
            font-size: 16px;
            font-weight: 400;
            line-height: 19.36px;
            text-align: left;
            color: #fff;
            margin-bottom: 4px;
        }
        &__input{
            padding: 6px 12px;
            width: calc(100% - 28px);
            margin-left: 28px;
            border: 1px solid;
            border-color: #313336;
            background-color: #232627;
            font-family: Inter;
            outline: none;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: #fff;
            border-radius: 8px;
            transition: 0.3s ease;
            margin-bottom: 16px;
            &::placeholder {
                color: #6C7275;
            }
            &:focus{
                border-color: #0084FF;
            }
        }
        &__textarea{
            min-height: 50px;
            max-height: 300px;
        }
        &__viewText{
            padding: 6px 12px;
            width: calc(100% - 28px);
            margin-left: 28px;
            border: 1px solid;
            border-color: #313336;
            background-color: #232627;
            font-family: Inter;
            outline: none;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: #fff;
            border-radius: 8px;
            transition: 0.3s ease;
            margin-bottom: 20px;
        }
        &__add{
            display: flex;
            align-items: center;
            gap: 12px;
            font-family: Inter;
            font-size: 16px;
            font-weight: 400;
            line-height: 19.36px;
            text-align: left;
            color: #fff;
            margin-bottom: 4px;
            padding: 6px 12px;
            border: 1px solid #0084FF;
            width: max-content;
            border-radius: 8px;
            cursor: pointer;
            margin-bottom: 20px;
            transition: 0.3s ease;
            margin-left: 28px;
            position: relative;
            &:hover{
                background-color: #006ed4;
            }
        }
        &__checkListBar{
            display: flex;
            gap: 10px;
            align-items: center;
            margin-bottom: 14px;
            margin-left: 28px;
            &>p{
                font-family: Inter;
                font-size: 12px;
                font-weight: 400;
                line-height: 14.52px;
                text-align: center;
                color: #0084FF;
                width: 32px;
            }
            &__wrapper{
                width: 100%;
                max-width: 500px;
                border-radius: 30px;
                height: 8px;
                background-color: #232627;
                position: relative;
                &__inner{
                    position: absolute;
                    top: 0;
                    left: 0;
                    border-radius: 30px;
                    height: 100%;
                    width: 300px;
                    background-color: #0084FF;
                    transition: width 0.3s linear;
                }
            }
        }

        &__grid{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 15px;
            margin-bottom: 20px;
            &__item{

                &>p{
                    display: flex;
                    align-items: center;
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.36px;
                    text-align: left;
                    color: #fff;
                    margin-bottom: 4px;
                }

                &>div input{
                    padding: 6px 12px;
                    border: 1px solid;
                    border-color: #313336;
                    background-color: #232627;
                    font-family: Inter;
                    outline: none;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: left;
                    color: #fff;
                    border-radius: 8px;
                    transition: 0.3s ease;
                    &::placeholder {
                        color: #6C7275;
                    }
                    &:focus{
                        border-color: #0084FF;
                    }
                }
            }
        }
        &__checkListItem{
            display: flex;
            gap: 12px;
            align-items: center;
            margin-left: 28px;
            margin-bottom: 8px;
            position: relative;
            cursor: pointer;

            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.94px;
            text-align: left;
            color: #fff;
            width: max-content;

            &>input[type="checkbox"] {
                appearance: none; 
                width: 16px;
                height: 16px;
                border: 1px solid #333738;
                border-radius: 3px;
                background-color: transparent;
                cursor: pointer;
                transition: 0.3s ease;
                &:checked {
                    background-color: #0084FF; 
                    border-color: #333738;
                }
            }
            &__checkedImage{
                position: absolute;
                left: 1px;
            }
            &__removeItem{
                position: absolute;
                width: 16px;
                height: 16px;
                right: -30px;
                box-shadow: 0px 4px 4px 0px #00000040;
            }
        }
        &__attachmentItem{
            display: flex;
            align-items: center;
            gap: 10px;
            margin-left: 28px;
            margin-bottom: 12px;
            position: relative;
            width: max-content;
            &>img{
                width: 68px;
                height: auto;
                border-radius: 5px;
                overflow: hidden;
                box-shadow: 0px 4px 4px 0px #00000040;
            }
            &>p{
                font-family: Inter;
                font-size: 14px;
                font-weight: 400;
                line-height: 16.94px;
                text-align: left;
                color: #fff;
            }
            &__removeItem{
                width: 24px !important;
                height: 24px !important;
                position: absolute;
                cursor: pointer;
                right: -30px;
            }
        }
        &__comment{
            display: flex;
            gap: 10px;
            margin-bottom: 12px;
            &__left{
                &>img{
                    width: 20px;
                    height: auto;
                    min-height: 20px;
                    min-width: 20px;
                    border-radius: 50%;
                }
            }
            &__right{
                display: flex;
                flex-direction: column;
                gap: 8px;
                &>p{
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.94px;
                    text-align: left;
                    color: #fff;
                }
                &>span{
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14.52px;
                    text-align: left;
                    color: #6C7275;
                }
            }
        }

        &__saveBtn{
            padding: 15px;
            border-radius: 8px;
            background-color: #0084FF;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        
            font-family: Inter;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            text-align: center;
            color: #fff;
        }
        &__infoP{
            font-family: Inter;
            font-size: 16px;
            font-weight: 400;
            line-height: 19.36px;
            text-align: left;
            color: #b12929;
            padding: 4px;
            text-align: center;
        }
    }
}
