.chatBody__input{
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    height: auto;
    margin-left: 20px;
    margin-bottom: 20px;
    border-radius: 13px;
    // border: 2.17px solid #343839;
    background-color: rgba(0, 0, 0, 0.8);
    border: 1px solid rgba(255, 255, 255, 0.2);
    &__top{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px;
        padding-top: 5px;
        padding-bottom: 4px;
        & > textarea {
            background: transparent;
            border: none;
            outline: none;
            width: 100%;
            padding-left: 25px;
            padding-right: 25px;
            padding-top: 12px;
            font-family: Inter;
            font-size: 18px;
            font-weight: 400;
            line-height: 26px;
            text-align: left;
            color: #6C7275;
            height: auto;
            max-height: 52px;
            overflow-y: scroll;
            resize: none;
            &:focus {
                outline: none;
                background: transparent;
            }
        }
        &>img{
            cursor: pointer;
        }
    }
    &__bottom{
        display: grid;
        gap: 4px;
        height: auto;
        max-height: 0px;
        overflow: hidden;
        transition: 0.3s ease;
        grid-template-columns: repeat(auto-fit, minmax(70px, 70px));
        &>div{
            max-width: 70px;
            height: auto;
        }
    }
    &__bottom__open{
        max-height: 100px;
        margin-top: 10px;
        padding: 0px 16px;
    }
}