.component__users{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding: 20px;
    &__top{
        display: flex;
        margin-bottom: 20px;

        &>p{
            cursor: pointer;
            padding: 10px 20px;
            border: 1px solid white;
            border-radius: 5px;
            color: white;
        }
    }
}

.createUserModal{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.4);

    &__wrapper{
        padding: 40px;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 680px;
        background-color: #2C2E38;

        &__infoP{
            font-family: Inter;
            font-size: 16px;
            font-weight: 400;
            line-height: 16.94px;
            text-align: left;
            color: #df3131;
        }

        &>input{
            width: 100%;
            padding: 20px 16px;
            height: 54px;
            outline: none;
            border: none;
            background-color: #373A43;
            font-family: Inter;
            font-size: 16px;
            font-weight: 400;
            line-height: 19.36px;
            text-align: left;
            color: white;
            margin-bottom: 20px;
        }

        &__headline{
            font-family: Inter;
            font-size: 18px;
            font-weight: 600;
            line-height: 21.78px;
            letter-spacing: 1px;
            text-align: left;
            color: #fff;
            margin-bottom: 24px;
        }

        &__label{
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.94px;
            text-align: left;
            color: #fff;
            margin-bottom: 10px;
        }

        &__btns{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            height: auto;
            gap: 10px;

            &__btn{
                padding: 12px 40px 12px 40px;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                font-family: Inter;
                font-size: 16px;
                font-weight: 400;
                line-height: 19.36px;
                text-align: left;
                color: #fff;
            }
        }

        &__flags{
            display: flex;
            flex-direction: column;
            gap: 10px;
            &__checkbox{
                display: flex;
                gap: 10px;
                color: white;
                font-family: Inter;
                font-size: 14px;
                font-weight: 400;
                line-height: 16.94px;
                text-align: left;
            }
        }
    }
}