.notifications{
    position: fixed;
    z-index: 2;
    bottom: 0;
    right: 20px;
    width: 100%;
    max-width: 320px;
    padding: 20px 0px;
    &__clearAll{
        width: 100%;
        background: #0084FF;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: #fff;
        padding: 6px;
        border-radius: 8px;
    }
    &__items {
        display: flex;
        flex-direction: column;
    }
}

.notification__item {
    background-color: #333;
    border: 1px solid #555;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    position: relative;
    color: #fff;
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    height: 100px;
    max-height: 100px;
    opacity: 1;
    overflow: hidden;

    &--info {
        border-left: 5px solid #17a2b8;
    }

    &--warning {
        border-left: 5px solid #ffc107;
    }

    &--error {
        border-left: 5px solid #dc3545;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__date {
        font-size: 0.85em;
        color: #bbb;
    }

    &__close {
        width: 16px;
        height: 16px;
        cursor: pointer;
    }

    &__content {
        margin-top: 10px;
        font-size: 0.95em;
        color: #ccc;
    }

    &__timer {
        height: 4px;
        background-color: #555;
        margin-top: 10px;
        border-radius: 2px;
        overflow: hidden;
    }

    &__timer-line {
        height: 100%;
        background-color: #007bff;
        transition: width 0.1s linear;
    }

    

    &.closing {
        max-height: 0px; 
        opacity: 0; 
    }

    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__close {
        cursor: pointer;
    }

    &__timer {
        margin-top: 10px;
    }

    &__timer-line {
        height: 4px;
        background-color: #4caf50;
        transition: width 0.1s ease;
    }
}