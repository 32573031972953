.component__tasks {
    width: 100%;
    height: auto;
    display: flex;
    height: 100%;

    .component__innerNavbar{
        display: flex;
        flex-direction: column;
        width: 300px;
        border-right: 2px solid #343839;
        height: 100%;
        min-height: 100vh;
        max-height: 100vh;
        overflow: scroll;
        background-color: #141718;
        gap: 4px;
        &__head{
            width: 100%;
            height: 68px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            margin-bottom: 16px;
            border-bottom: 2px solid #343839;
            &__header{
                font-family: Inter;
                font-size: 20px;
                font-weight: 400;
                line-height: 24.2px;
                text-align: left;
                color: #fff;
            }
            &>div{
                display: flex;
                align-items: center;
                gap: 6px;
                &>div{
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }
            }
        }
        &__item{
            display: flex;
            flex-direction: column;
            padding: 10px;
            width: 100%;
            border-radius: 8px;
            cursor: pointer;
            position: relative;
            &--dot{
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: red;
                position: absolute;
                left: 10px;
                top: 50px;
            }
            &__top{
                display: flex;
                align-items: center;
                gap: 14px;
                color: #E8ECEFBF;
                &__left{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &>img{
                        width: 44px;
                        height: 44px;
                    }
                }
                &__right{
                    &__name{
                        font-family: Inter;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 19.36px;
                        text-align: left;
                        color: #FFF;
                    }
                }
            }
            &__bottom{
                display: flex;
                flex-direction: column;
                gap: 2px;
                height: auto;
                width: 100%;
                max-height: 0px;
                overflow: hidden;
                transition: 0.3s ease;
                margin-top: 4px;

                &__item{
                    font-family: Inter;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 19.36px;
                    text-align: left;
                    color: #E8ECEFBF;
                    width: 100%;
                    padding: 4px 6px;
                    padding-left: 56px;
                    border-radius: 6px;
                    transition: 0.3s ease;
                    background-color: transparent;
                    transition: 0.3s ease;
                    position: relative;

                    &:hover{
                        color: #fff;
                        background-color: rgba(70, 79, 111, 0.3);
                    }

                    &--dot{
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background-color: red;
                        position: absolute;
                        left: 50px;
                        bottom: 4px;
                    }
    
                    &__active{
                        color: #fff;
                        background: linear-gradient(0deg, rgba(70, 79, 111, 0.3), rgba(70, 79, 111, 0.3));
                    }
                }

                &__active{
                    max-height: 1000px;
                }
            }
            
            &__opened{
                .component__innerNavbar__item__top{
                    color: #fff;
                    &__right{
                        &__name{
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
    &__list{
        width: calc(100% - 300px);
        background-image: url("../../../public/images/backgroundImage.png"); 
        background-position: center;
        background-repeat: no-repeat; 
        background-size: cover; 
    }
}