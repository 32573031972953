.component__admin__teams {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding: 20px;
    &__top {
        display: flex;
        margin-bottom: 20px;

        &>p {
            cursor: pointer;
            padding: 10px 20px;
            border: 1px solid white;
            border-radius: 5px;
            color: white;
        }
    }
}





.teamChannelModal{
    width: 100%;
    height: auto;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.8);
    padding: 20px;
    &__closeBtn{
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: #6C5DD3;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;

        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #5A49CE;
        }
    }
    &__wrapper{
        width: 100%;
        max-width: 1600px;
        background-color: #2C2E38;
        padding: 20px;
        position: relative;
        &__headline{
            margin-bottom: 30px;
        }
        &__addChannel {
            display: flex;
            margin-bottom: 20px;
            position: absolute;
            top: 20px;
            left: 20px;
    
            &>p {
                cursor: pointer;
                padding: 10px 20px;
                border: 1px solid white;
                border-radius: 5px;
                color: white;
            }
            
        }
    }
}