@import "../../scss/mixins.scss";

.component__sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 80px;
    height: 100%;
    background: #141718;
    padding: 20px;
    border-right: 2px solid #343839;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transition: width 0.3s ease;

    &__top{
        width: 100%;
        &__line{
            width: 100%;
            height: 0px;
            border: 1px solid #343839;
            margin-top: 16px;
            margin-bottom: 8px;
        }
        &__headline{
            font-family: Inter;
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            text-align: left;
            color: #808191;
            margin-bottom: 8px;
        }
    }
    &__bottom{
        width: 100%;
    }

    &__item{
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 2px;
        cursor: pointer;
        font-family: Inter;
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
        text-align: left;
        color: #E8ECEFBF;
        background-color: #141718;
        border-radius: 5px;
        margin-bottom: 8px;
        padding: 10px;
        overflow: hidden;
        transition: 0.3s ease;
        position: relative;

        &--dot{
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: red;
            position: absolute;
            left: 5px;
            bottom: 10px;
        }

        &:hover{
            background-color: rgba(70, 79, 111, 0.3);
            color: white;
        }
        &--active{
            color: #fff;
            background: linear-gradient(0deg, rgba(70, 79, 111, 0.3), rgba(70, 79, 111, 0.3));
        }
        &>img{
            width: 20px;
            height: 20px;
        }
        &>p{
            word-break:keep-all;
            padding-left: 10px;
            width: max-content;
        }
        &>span{
            word-break:keep-all;
            padding-left: 10px;
            width: max-content;
        }
        .logoImg{
            margin-left: auto;
        }
        .logoHeadline{
            font-family: MuseoModerno;
            font-size: 30px;
            font-weight: 500;
            line-height: 47.7px;
            text-align: left;
            color: #FEFEFE;
            padding-left: 0;
        }
    }

    &__bottom{
        margin-top: auto;
    }
}

.component__sidebar__opened{
    width: 340px;
    .component__sidebar__item{
        width: 100%;
        height: 40px;
        display: flex;
        gap: 25px;
        margin-bottom: 8px;
        text-align: center;
        // padding: 25px 16px;
    }
    .component__sidebar__item__opened{
        padding-left: 0px;
    }
}

.component__sidebar-mobile{
    bottom: 0;
    left: 0;
    top: unset;
    width: 100%;
    height: 68px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-top: 0;
    border-top: 1px solid #373A43;
    .component__sidebar__item{
        width: 56px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2px;
        cursor: pointer;
        border-radius: 5px;
    }
}


.component__sidebar__language-item{
    &>img{
    }
}