* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html, body, #root {
  width: 100%;
  height: 100%;
}
#root {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #232630;
}
.root__content {
  width: 100%;
  height: auto;
  height: 100%;
}