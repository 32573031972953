.component__settingsModal{
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #232627D1;
    &__wrapper{
        width: 100%;
        max-width: 780px;
        height: 100%;
        max-height: 530px;
        border-radius: 20px;
        display: flex;
        background: #141718;
        padding: 40px 40px 40px 30px;
        gap: 48px;
        position: relative;
        &__sidebar{
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 212px;
            gap: 4px;
            &__p{
                font-family: Inter;
                font-size: 14px;
                font-weight: 500;
                line-height: 16.94px;
                text-align: left;
                color: #fff;
                cursor: pointer;

                display: flex;
                align-items: center;
                gap: 12px;

                width: 100%;
                padding: 14px;
                border-radius: 32px;
                border: 1px solid;
                border-color: transparent;

                transition: 0.3s ease;

                &:hover{
                    border-color: #3E90F0;
                }
            }
            .settingsModal__item__active{
                border-color: #3E90F0;
            }
        }
        &__content{
            width: 100%;
        }
        &__close{
            position: absolute;
            right: 30px;
            top: 30px;
            cursor: pointer;
        }
    }
}

.editProfileTab{
    &__imageSection{
        
        &__top{
            display: flex;
            align-items: center;
            gap: 80px;
            margin-bottom: 24px;
            &__left{
                &>img{
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                }
            }
            &__right{
                &__upload{
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.94px;
                    text-align: left;
    
                    margin-bottom: 20px;
    
                    cursor: pointer;
    
                    display: flex;
                    justify-content: center;
                    align-items: center;
    
                    color: #fff;
                    padding: 15px 22px;
                    border: 1px solid;
                    border-color: #343839;
                    border-radius: 5px;
    
                    position: relative;
    
                    transition: 0.3s ease;
                    &:hover{
                        border-color: #3E90F0;
                    }
    
                }
            }
        }
        
    }
}


.settingsTab__header{
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.05px;
    text-align: left;
    color: #fff;
}
.settingsTab__label{
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
    margin-bottom: 12px;
    color: #fff;
}
.settingsTab__saveBtn{
    padding: 15px;
    border-radius: 8px;
    background-color: #0084FF;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #fff;
}
.settingsTab__input{
    position: relative;
    margin-bottom: 20px;
    &>input{
        padding: 15px 42px;
        width: 100%;
        border: 1px solid;
        border-color: #313336;
        background-color: #232627;
        font-family: Inter;
        outline: none;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #fff;
        border-radius: 8px;
        transition: 0.3s ease;
        &::placeholder {
            color: #6C7275;
        }
        &:focus{
            border-color: #0084FF;
        }
    }
    &>img{
        position: absolute;
        top: 20px;
        left: 14px;
    }
}

.settingsAppearance__inner{
    display: flex;
    gap: 40px;
    margin-bottom: 16px;
    &__item{
        padding: 20px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        color: #fff;
        min-width: 108px;
        cursor: pointer;
        transition: 0.3s ease;
        &:hover{
            background-color: #232627;
        }
    }
    .activeItem{
        background-color: #232627;
    }
}

.settingsTab__infoP{
    padding: 4px;
    text-align: center;
    color: rgb(72, 151, 8);
}