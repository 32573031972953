.chatBody{
    display: grid;
    grid-template-rows: max-content 1fr max-content;
    width: calc(100% - 360px);
    height: 100vh;
    background-color: #232627;
    background-image: url("../../../../public/images/backgroundImage.png"); 
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover; 
}