@import "../../scss/mixins.scss";

.component__login {
    width: 100%;
    height: 100%;

    background-color: #141718;

    display: flex;
    justify-content: center;
    align-items: center;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 412px;

        &__logo {
            width: 60px;
            height: 70px;
            background-size: 100% 100%;
            margin-bottom: 20px;
        }
        &__heading {
            font-family: Inter;
            font-size: 47.46px;
            font-weight: 700;
            line-height: 57.44px;
            text-align: left;
            margin-bottom: 34px;
            color: #FFFFFF;
        }

        &__input {
            width: auto;
            height: auto;

            display: flex;
            flex-direction: column;
            gap: 14px;
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 40px;
            }
            & > span {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                
                color: #FFFFFF;
            }
            & > input {
                color: #FFFFFF;
                background-color: #141718;
                border: 1px solid #313336;
                outline: none;
                border-radius: 5px;
                width: 420px;
                height: 54px;
                padding: 0 20px;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 19px;

                transition: border-color 0.3s ease;

                &:focus {
                    border-color: #0084FF;
                }
            }
            &--error {
                & > input {
                    border-color: red;
                }
            }
        }
        &__button {
            width: 420px;
            height: 57px;
            margin-top: 50px;
            
            color: #FFFFFF;
            background: #0084FF;
            border-radius: 5px;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;

            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            transition: background-color 0.3s ease;

            &:hover {
                background-color: #5A49CE;
            }
        }
        &__infoP {
            margin-top: 30px;
            color: rgb(250, 75, 75);
            opacity: 0;

            transition: opacity 0.3s ease;
        }
    }
}


@media (max-width: $breakpoint-large) {
    .component__login {
        grid-template-columns: 1fr;
        grid-template-rows:  500px 1fr;
        height: auto;
        padding-top: 90px;
        &__left{
            max-height: 500px;
        }
        &__container{
            padding: 40px 20px;
        }
    }
}

@media (max-width: $breakpoint-small) {
    .component__login {
        padding-top: 90px;
        height: auto;
        grid-template-rows:  200px 1fr;
        &__left{
            max-height: 500px;
            height: 200px;
        }
        &__container{
            padding: 20px;
            &__logo {
                width: 45px;
                height: 52px;
                margin-bottom: 16px;
            }
            &__text {
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 16px;
            }
            &__heading {
                font-size: 24px;
                margin-bottom: 30px;
            }
            &__input {
                width: 100%;
                height: auto;
    
                display: flex;
                flex-direction: column;
                gap: 14px;
                margin-bottom: 16px;
    
                &:last-child {
                    margin-bottom: 30px;
                }
                & > input {
                    width: 100%;
                    max-width: 570px;
                    height: 42px;
                    padding: 0 14px;
                    font-size: 14px;
                }
            }
            &__button {
                width: 100%;
                height: 46px;
                margin-top: 30px;
            }
            &__infoP {
                margin-top: 16px;
            }
        }
    }
}