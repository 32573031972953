@import "../../scss/mixins.scss";

.component__chats {
    width: 100%;
    height: auto;
    display: flex;
    min-height: 100%;
    background-image: url("../../../public/images/backgroundImage.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .component__innerNavbar{
        display: flex;
        flex-direction: column;
        width: 360px;
        border-right: 2px solid #343839;
        height: 100%;
        min-height: 100vh;
        background-color: #141718;
        gap: 4px;
        &__head{
            width: 100%;
            height: 68px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            margin-bottom: 16px;
            border-bottom: 2px solid #343839;
            &__header{
                font-family: Inter;
                font-size: 20px;
                font-weight: 400;
                line-height: 24.2px;
                text-align: left;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
            }
            &>div{
                display: flex;
                align-items: center;
                gap: 6px;
                &>div{
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }
            }
        }
        &__headAdd{
            display: flex;
            align-items: center;
            &>img{
                width: 24px;
                height: 24px;
            }
        }
        &__item{
            display: flex;
            flex-direction: column;
            padding: 10px;
            width: 100%;
            border-radius: 8px;
            cursor: pointer;
            background: transparent;
            transition: 0.3s ease;
            position: relative;
            &--dot{
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: red;
                position: absolute;
                left: 10px;
                bottom: 4px;
            }
            &:hover{
                background: rgba(70, 79, 111, 0.3);
            }
            &__top{
                display: flex;
                align-items: center;
                gap: 14px;
                color: #E8ECEFBF;
                &__left{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &>img{
                        width: 44px;
                        height: 44px;
                        border-radius: 50%;
                    }
                }
                &__right{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                    &__top{
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        &__name{
                            font-family: Inter;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 19.36px;
                            text-align: left;
                            color: #FFF;
                            max-width: 200px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        &__date{
                            font-family: Inter;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 16.94px;
                            text-align: left;
                            color: #6C7275;
                        }
                    }
                    &__bot{
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        &__msg{
                            font-family: Inter;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 16.94px;
                            text-align: left;
                            color: #6c7275;
                            max-width: 260px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        &>img{
                            margin-left: auto;
                        }
                    }
                    
                }
            }
            &__bottom{
                display: flex;
                flex-direction: column;
                gap: 2px;
                height: auto;
                width: 100%;
                max-height: 0px;
                overflow: hidden;
                transition: 0.3s ease;
                margin-top: 4px;

                &__item{
                    font-family: Inter;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 19.36px;
                    text-align: left;
                    color: #E8ECEFBF;
                    width: 100%;
                    padding: 4px 6px;
                    padding-left: 56px;
                    border-radius: 6px;
                    transition: 0.3s ease;
                    background-color: transparent;
                    transition: 0.3s ease;

                    &:hover{
                        color: #fff;
                        background-color: rgba(70, 79, 111, 0.3);
                    }
    
                    &__active{
                        color: #fff;
                        background: linear-gradient(0deg, rgba(70, 79, 111, 0.3), rgba(70, 79, 111, 0.3));
                    }
                }

                &__active{
                    max-height: 1000px;
                }
            }
            
            &__opened{
                background: linear-gradient(0deg, rgba(70, 79, 111, 0.3), rgba(70, 79, 111, 0.3));
            }
        }
    }
}

.createTeamModal {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.4);

    &__wrapper {
        padding: 40px;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 680px;
        background-color: #2C2E38;

        &__infoP {
            font-family: Inter;
            font-size: 16px;
            font-weight: 400;
            line-height: 16.94px;
            text-align: left;
            color: #df3131;
        }

        &>input {
            width: 100%;
            padding: 20px 16px;
            height: 54px;
            outline: none;
            border: none;
            background-color: #373A43;
            font-family: Inter;
            font-size: 16px;
            font-weight: 400;
            line-height: 19.36px;
            text-align: left;
            color: white;
            margin-bottom: 20px;
        }

        &__headline {
            font-family: Inter;
            font-size: 18px;
            font-weight: 600;
            line-height: 21.78px;
            letter-spacing: 1px;
            text-align: left;
            color: #fff;
            margin-bottom: 24px;
        }

        &__label {
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.94px;
            text-align: left;
            color: #fff;
            margin-bottom: 10px;
        }

        &__btns {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            height: auto;
            gap: 10px;

            &__btn {
                padding: 12px 40px 12px 40px;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                font-family: Inter;
                font-size: 16px;
                font-weight: 400;
                line-height: 19.36px;
                text-align: left;
                color: #fff;
            }
        }

        &__flags {
            display: flex;
            flex-direction: column;
            gap: 10px;

            &__checkbox {
                display: flex;
                gap: 10px;
                color: white;
                font-family: Inter;
                font-size: 14px;
                font-weight: 400;
                line-height: 16.94px;
                text-align: left;
            }
        }

        &__addTeamMember {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px;
            width: 100%;
            cursor: pointer;
            background-color: rgb(108, 93, 211);
            font-family: Inter;
            font-size: 16px;
            font-weight: 400;
            line-height: 19.36px;
            text-align: left;
            color: #fff;
            border-radius: 5px;
            margin-bottom: 20px;
        }
        &__rightToChat{
            display: flex;
            justify-content: space-between;
            gap: 10px;
            &__p{
                cursor: pointer;
                font-family: Inter;
                font-size: 16px;
                font-weight: 400;
                line-height: 19.36px;
                text-align: left;
                color: #fff;
                padding: 10px 20px;
                margin-bottom: 20px;
                transition: 0.3s ease;
                border: 1px solid;
                border-color: #fff;
                border-radius: 5px;
            }
            .right-to-chat-active{
                background-color: rgb(108, 93, 211);
                border-color: rgb(108, 93, 211);
            }
        }

        
    }
}


.route__usersList__multiselect {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.8);
    z-index: 999;
    overflow: hidden;
    transition: 0.4s ease;

    &__inner {
        width: 100%;
        max-width: 1200px;
        background: #282F37;
        height: max-content;
        max-height: 780px;
        border-radius: 4px;
        padding: 20px;
        position: relative;

        &__genderBtns {
            position: absolute;
            top: -62px;
            display: flex;
            gap: 18px;

            &>p {
                padding: 10px;
                border-radius: 10px;
                border: 1px solid;
                border-color: #fff;
                min-width: 70px;
                text-align: center;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                transition: 0.3s ease;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
            .form__group {
                position: relative;
              }
              
              .form__field {
                font-family: inherit;
                width: 100%;
                border: 0;
                border-bottom: 2px solid #9b9b9b;
                outline: 0;
                font-size: 16px;
                color: #fff;
                padding: 7px 0;
                background: transparent;
                transition: border-color 0.2s;
                padding-top: 15px;
              
                &::placeholder {
                  color: transparent;
                }
              
                &:placeholder-shown ~ .form__label {
                  font-size: 16px;
                  cursor: text;
                  top: 20px;
                }
              }
              
              .form__label {
                position: absolute;
                top: 0;
                display: block;
                transition: 0.2s;
                font-size: 1rem;
                color: #9b9b9b;
              }
              
              .form__field:focus {
                ~ .form__label {
                  position: absolute;
                  top: 0;
                  display: block;
                  transition: 0.2s;
                  font-size: 1rem;
                  color: #38ef7d;
                  font-weight:500;    
                }
                padding-bottom: 6px;  
                font-weight: 500;
                border-width: 3px;
                border-image: linear-gradient(to right,#38ef7d ,#11998e);
                border-image-slice: 1;
              }
              /* reset input */
              .form__field{
                &:required,&:invalid { box-shadow:none; }
              }
        }

        &__suggestedHeadline {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 6px;
        }

        &__data {
            width: 100%;
            height: 100%;
            max-height: 700px;
            overflow-y: scroll;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 8px;

            &>div {
                padding: 10px;
                text-align: center;
                transition: 0.3s ease;
                height: auto;
                // &:hover {
                //   box-shadow: inset 0 0 10px 2px rgba(0, 0, 255, 1);
                // }
            }

            .data--item--selected {
                border-color: #00a30e !important;
                color: #00a30e !important;
            }
        }

    }

    .route__usersList__createComment__wrap__btns {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        &>p {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            cursor: pointer;
            padding: 10px 20px;
            
            &::after {
                content: '';
                position: absolute;
                bottom: -6px;
                left: unset;
                right: 0;
                width: 0%;
                height: 2px;
                background-color: #2c7de3;
                transition: left 0.3s ease, right 0.3s ease, width 0.3s ease;
            }

            &:last-child::after {
                background-color: #EA3F3F;
            }

            &:hover::after {
                right: unset;
                left: 0;
                width: 100%;
            }

            &>span {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
            }
        }
    }
}