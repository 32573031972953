.component__taskSystem{
    padding: 20px 16px;
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 276px;
    gap: 12px;
    overflow-x: auto;
    max-height: 100%;
    min-height: 100vh;
    background-image: url("../../../../public/images/backgroundImage.png"); 
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover; 
    &__card{
        border-radius: 10px;
        // background-color: #141718;
        background-color: rgba(0, 0, 0, 0.7);
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6);  // Subtle shadow for depth
        backdrop-filter: blur(10px); 
        padding: 14px 8px;
        display: grid;
        grid-template-rows: 30px 1fr 36px;
        height: max-content;
        max-height: calc(100vh - 40px);

        &__headline{
            display: flex;
            align-items: center;
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.94px;
            text-align: left;
            color: #FFF;
            margin-bottom: 14px;
            padding-left: 16px;
            &>img{
                margin-left: auto;
                width: 20px;
                height: 20px;
            }
        }
        &__content{
            display: flex;
            flex-direction: column;
            gap: 8px;
            border-radius: 5px;
            height: auto;
            overflow: scroll;
            &__item{
                padding: 8px 12px;
                // background-color: #323337;
                background-color: rgba(31, 31, 31, 0.5);
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
                display: flex;
                flex-direction: column;
                gap: 6px;
                border-radius: 5px;
                cursor: pointer;
                position: relative;
                transition: 0.3s ease;
                &:hover{
                    background-color: rgba(31, 31, 31, 0.75); 
                }
                &--dot{
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: red;
                    position: absolute;
                    left: 4px;
                    top: 4px;
                }
                &__top{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    &__name{
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16.94px;
                        text-align: left;
                        color: #fff;
                    }
                    &__priority{
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 14.52px;
                        text-align: left;
                        padding: 4px 6px;
                        border-radius: 4px;
                        background-color: yellow;
                    }
                    .priority__High{
                        color: #EC5868;
                        background: #EC586826;
                    }
                    .priority__Medium{
                        background: #52BA6926;
                        color: #52BA69;
                    }
                    .priority__Low{
                        background: #D58D4926;
                        color: #D58D49;
                    }
                }
                &__bot{
                    display: flex;
                    gap: 16px;
                    &__item{
                        display: flex;
                        align-items: center;
                        gap: 3px;
                        position: relative;
                        &>p{
                            font-family: Inter;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 16.94px;
                            text-align: left;
                            color: #fff;
                        }
                        &__assignedto{
                            padding: 4px;
                            border-radius: 50%;
                            background-color: rebeccapurple;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .tooltip-text {
                            visibility: hidden;
                            width: max-content;
                            background-color: black;
                            color: #fff;
                            text-align: center;
                            border-radius: 5px;
                            padding: 5px;
                            position: absolute;
                            z-index: 1;
                            bottom: 10px;
                            right: 10px;
                            opacity: 0;
                            transition: opacity 0.3s;
                        }
                    
                        &:hover .tooltip-text {
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
            }
        }
        &__add{
            display: flex;
            gap: 8px;
            margin-top: 16px;
            cursor: pointer;
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.94px;
            text-align: left;
            color: #fff;
        }
    }
    &__addList{
        border-radius: 10px;
        background-color: #141718;
        padding: 14px 8px;
        height: max-content;
        max-height: calc(100vh - 40px);
        display: flex;
        gap: 8px;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.94px;
        text-align: left;
        color: #fff;
        cursor: pointer;
        position: relative;
        &__inner{
            position: absolute;
            border-radius: 10px;
            background-color: #141718;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 4px;
            left: 0;
            padding: 0px 8px;
            top: 30px;
            max-height: 0;
            overflow: hidden;
            transition: 0.3s ease;
            &__top{
                & > input{
                    width: 100%;
                    height: 32px;
                    padding: 0 12px;

                    color: white;
                    background: #141718;
                    border-radius: 5px;
                    border: 1px solid #373A43;
                    outline: none;
                }
            }
            &__bottom{
                display: flex;
                align-items: center;
                gap: 8px;
                &>p{
                    padding: 8px 12px;
                    border-radius: 5px;
                    border: 1px solid #373A43;
                    cursor: pointer;
                }
                &>img{
                    width: 24px;
                    height: 24px;
                }
            }
        }
        .innerOpen{
            padding: 14px 8px;
            max-height: 100px;
        }
    }
}